import { Observable, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable({
 providedIn: 'root'
})
export class ApiService {
  LanguageService = { 
    selectedLanguage: 'en',
    translations: '',
    flags: '',
    languages: '',
  };

  headers = {
    'Authorization' : 'Bearer ' + '', //$rootScope.getToken(),
    'App-Version' : environment.versionCode
  }
  constructor( private httpClient: HttpClient) { }

  public postData(link, formdata){
    return this.httpClient.post('http://localhost:4201/'+link, formdata);
  };
	  
  public getLanguages() {
    var lang = localStorage.getItem('language');
    return this.httpClient.get(environment.baseUrl + 'language/get_user_langs'+(lang?'?language='+lang:''));
    
    // .then(function(data) {
    //   if (data) {
    //     this.LanguageService.translations = data.data.translations;
    //     this.LanguageService.flags = data.data.flags;
    //     this.LanguageService.languages = Object.keys(data.data.flags); //(Object.keys(LanguageService.translations.lang_flag)).filter(a=>{return a!='name'});
    //     this.LanguageService.setLanguage();
    //     return this.LanguageService.translations;
    //   }
    // }, function(e) {
    //   throw e;
    // });

  };
		
  public setLanguage = function(){
    var lang = localStorage.getItem('language');
    if(!lang){
      // var navLanguage = navigator.language ? navigator.language : navigator.userLanguage ;
      var navLanguage = navigator.language;
      if(navLanguage){
        var index = this.LanguageService.languages.findIndex(element => navLanguage.includes(element));
        if(index > -1)
          lang = this.LanguageService.languages[index];
      }
    }
    this.LanguageService.changeLanguage(lang,false);
  }

  public login(dict){
    return this.httpClient.request('POST', environment.baseUrl + 'user/login', {
      body: dict,
      headers: this.headers
    }).toPromise().catch((res) => this.handleError(res));
  }

  handleError(response: any) {
    return {
      status: 0,
      error: response.error
    };
  }

  public register(dict){
    return this.httpClient.request('POST', environment.baseUrl + 'user/register', {
      body: dict,
      headers: this.headers
    }).toPromise().catch((res) => this.handleError(res));
  }
}