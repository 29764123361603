import { Platform, getPlatforms } from '@ionic/angular';

import { ApiService } from './services/api.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Home', url: '/home', icon: 'home' },
    { title: 'Language', url: '/languages', icon: 'language' },
    { title: 'Route Settings', url: '/route-settings', icon: 'settings' },
    { title: 'User Settings', url: '/user-settings', icon: 'person-add' },
    { title: 'Purchase of Subscription', url: '/subscriptions', icon: 'wallet' },
    { title: 'My Subscriptions', url: '/my-subscriptions', icon: 'cash' },
    { title: 'Privacy Policy & Terms', url: '/privacy-policy', icon: 'document-text' },
    { title: 'Report a Problem', url: '/report-problem', icon: 'warning' },
    { title: 'Logout', url: '/login', icon: 'log-out' },
  ];
  LanguageService = { 
    selectedLanguage: 'en',
    translations: {},
    flags: '',
    languages: {},
  };


  constructor(public apiService: ApiService, public platform: Platform) {
    if(!localStorage.getItem('token')){
      localStorage.setItem('language', 'en');
    } 

    let platformName = this.platform.is('ios') === true ? 'ios' : this.platform.is('android') === true ? 'android' : 'browser';
    localStorage.setItem('platform', platformName);
    
    this.loadLanguage();
  }

  loadLanguage(){
    this.apiService.getLanguages().subscribe((data:any) => {
      if (data) {
        this.LanguageService.translations = data.translations;
        localStorage.setItem('selectedLanguage', 'en');
        localStorage.setItem('translations', JSON.stringify(this.LanguageService.translations));
        this.LanguageService.flags = data.flags;
        this.LanguageService.languages = Object.keys(data.flags); //(Object.keys(LanguageService.translations.lang_flag)).filter(a=>{return a!='name'});
        // this.LanguageService.setLanguage();
      }
    },
    err => {
      console.log(err)
    });
  }
}
